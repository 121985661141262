<template>
  <div class="auth-right">
    <div class="auth-right-header zoom">
      <router-link to="/auth/login" class="btn btn-outline-primary">
        {{ $t('buttons.backtologin') }}
      </router-link>
      <!--<a href="#" class="menu-btn">
        <v-icon icon="bars" />
      </a> -->
    </div>
    <loading :loading="loading" />
    <!--<div style="margin-top:180px"></div>-->
    <!--jimir3x-->
    <div
      v-show="!loading"
      class="auth-right-content auth-right-content-forgot-password zoom"
    >
      <!--jimir3x-->
      <!-- <h4 class="auth-titles-big text-secondary text-left">Forgot Password</h4> -->
      <h5 class="auth-title-semi mt-10">
        {{ $t('auth.password.reset-text') }}
      </h5>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(formSubmit)" action="#">
          <div class="form-group mb-5">
            <validation-provider
              name="email"
              :rules="{ required: true, min: 3, email: true }"
              v-slot="validationContext"
            >
              <label for="#signInEmail">
                {{ $t('auth.general.emailLabel') }}
              </label>
              <div
                class="input-group input-icon bg"
                :class="{
                  'is-invalid':
                    !getValidationState(validationContext) &&
                    validationContext.errors[0],
                }"
              >
                <b-form-input
                  :placeholder="$t('auth.general.email')"
                  type="email"
                  id="signInEmail"
                  name="loginEmail"
                  v-model.trim="form.email"
                ></b-form-input>
                <div class="input-group-append">
                  <span class="input-group-text">
                    <v-icon :icon="['fac', 'user']" />
                  </span>
                </div>
              </div>
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </validation-provider>
          </div>

          <div class="form-group mt-4 mb-0">
            <button type="submit" class="btn btn-primary btn-block">
              {{ $t('buttons.respass') }}
            </button>
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import loading from '../../../components/global/loading/index';
import forgotPassword from '@/graphql/auth/forgotPassword.gql';
export default {
  name: 'Login-page',
  components: {
    loading,
  },
  data() {
    return {
      loading: false,
      form: {
        email: null,
      },
    };
  },
  methods: {
    async formSubmit() {
      try {
        this.loading = true;
        let variable = {
          email: this.form.email,
        };
        let data = await this.$apollo.mutate(forgotPassword, variable);
        let response = data('**.changePassRequest');
        console.log(response);

        if (response == true) {
         return this.$bvToast.toast(this.$t("Toaster.control-mail"), {
          title: this.$t("Toaster.info-title"),
          variant: "info",
          autoHideDelay: 1350,
        });

        }
      } catch (e) {
        return this.$bvToast.toast(this.$t(e.message), {
          title: this.$t("Toaster.error-title"),
          variant: "danger",
          autoHideDelay: 1350,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
